const AboutUsComponent = () => {
  return (
    <section>
      <div className="aboutUs">
        <div className="context">
          <h1>کارت دعوت عروسی آنلاین</h1>
          <p>
            کارت عروسی دیجیتال، روشی مدرن و شیک برای دعوت مهمان‌ها به مراسم
            شماست که علاوه بر کاهش هزینه‌ها، به مدیریت بهتر مراسم کمک می‌کند. با
            استفاده از این سرویس، می‌توانید دعوت‌نامه‌های شخصی‌سازی شده ارسال
            کنید، آدرس تالار را مستقیماً در ویز، نشان یا گوگل مپ به اشتراک
            بگذارید و از امکاناتی نظیر ارسال پیامک یادآوری مراسم، تشکر از
            مهمان‌ها و حتی رزرو پارکینگ برای مهمان‌های نزدیکتر بهره‌مند شوید.
          </p>
          <p>
            ویژگی‌های خلاقانه مانند امکان ثبت پیام‌های تبریک و یادبود توسط
            مهمان‌ها، پخش موزیک دلخواه در پس‌زمینه کارت، ساخت انواع نظرسنجی برای
            تخمین دقیق تعداد مهمان‌ها و قالب‌های متنوع کارت، تجربه‌ای بی‌نظیر
            برای شما و مهمانانتان فراهم می‌کند. همچنین، آمار دقیق از تعداد
            مهمان‌ها و جزئیات مراسم، مدیریت شما را آسان‌تر خواهد کرد. اگر به
            دنبال بهترین راه برای ماندگار کردن خاطره مراسم خود هستید، کارت عروسی
            دیجیتال بهترین انتخاب است.
          </p>
        </div>
        {/* <div className="videoBox">
          <video controls autoPlay style={{ width: "100%" }}>
            <source
              src="/asset/media/video/aroosi-online.mp4"
              type="video/mp4"
            />
            مرورگر شما از پخش ویدیو پشتیبانی نمی‌کند.
          </video>
        </div> */}
        {/* <img
          alt=""
          className="image"
          src="/asset/media/image/aros-damad2.jpg"
        /> */}
      </div>
    </section>
  );
};

export default AboutUsComponent;
