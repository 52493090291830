import { useNavigate, useParams } from "react-router-dom";
import * as data from "../../data.js";
// import { useCartActions } from "../../Providers/CartProvider.js";
import Layout from "../../Layout/Layout.js";
import "../Plan/PlanPage.css";
import { Box, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { useAuth } from "../../auth/authContext.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useCartActions } from "../../Providers/CartProvider.js";
import { useEffect, useState } from "react";
import { getLicenceInfo } from "../../services/licence.js";
import { formatNumberWithCommas } from "../../Utils/formatNumberWithCommas.js";
import { Helmet } from "react-helmet-async";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const PlanPage = () => {
  const { planId } = useParams();
  const plan = data.plans.find((plan) => plan.id == planId);
  const navigate = useNavigate();
  const { token, isAuthenticated } = useAuth();
  const dispatch = useCartActions();
  const [licenceInfo, setLicenceInfo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getLicenceInfo();
        setLicenceInfo(data);
      } catch (err) {
      } finally {
      }
    };

    fetchData();
  }, []);


  const addPlanHandler = (plan) => {
    if (!isAuthenticated) {
      if (plan.id === 1) {
        navigate("/login", { state: { redirectPath: "/dashboard" } });
      } else {
        navigate("/login", { state: { redirectPath: "/cart" } });
        return;
      }
    } else {
      if (plan.id === 1) {
        navigate("/dashboard", { replace: false });
      } else {
        dispatch({ type: "ADD_TO_CART", payload: plan });
        navigate("/cart", { replace: false });
      }
    }
  };

  // const handleSubmit = (values) => {
  //     axios.post(`${BASE_URL}/api/licence/buy`, { licence_id: planId },
  //         {
  //             headers: {
  //                 'Authorization': `Bearer ${token}`
  //             }
  //         })
  //         .then(response => {
  //             toast.success(response?.data?.message);
  //             navigate('/dashboard', { replace: false });
  //         })
  //         .catch(error => {
  //             toast.error(error?.response?.data?.message);
  //         });
  // };

  return (
    <>
      <Helmet>
        <title>عروسی آنلاین - پکیج</title>
        <meta name="description" content="پکیج عروسی آنلاین" />
      </Helmet>
      <Layout>
        <main className="planPage">
          <img
            alt=""
            className="flowerLeft"
            src="/asset/media/pic/flower-header1.png"
          />
          {/* <img alt="" className="flowerRight" src="/asset/media/pic/flower-header2.png" /> */}
          {/* <img alt="" className="flowerBottom" src="/asset/media/pic/flower-header3.png" /> */}
          {/* <Formik> */}
          <Grid container>
            <Grid item lg={12} xs={10}>
              <Typography variant="h2">پکیج {plan?.title}</Typography>
              <Box style={{ margin: "20px 0" }}>
                {/* <Typography variant="h5">امکانات این پکیج:</Typography> */}

                {plan?.planOption?.map((option, index) => (
                  <Box
                    key={option.id}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      alt=""
                      style={{ width: "16px", marginLeft: "10px" }}
                      src={
                        option.disable === "disable"
                          ? "/asset/media/icon/minus.png"
                          : "/asset/media/icon/plus.png"
                      }
                    />
                    <p
                      className={option.disable === "disable" ? "disable" : ""}
                    >
                      {option.option}
                    </p>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item lg={4} xs={11}>
              <Box>
                <Typography></Typography>
              </Box>
            </Grid>
            <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
              <p className="cost">
                {plan?.cost} {plan.id !== 1 ? "تومان" : ""}
              </p>
              <p className="off">
                {plan.id !== 1
                  ? formatNumberWithCommas(licenceInfo[1]?.price)
                  : "رایگان"}
              </p>
              {plan.id === 1 && isAuthenticated ? (
                <p style={{ paddingBottom: "20px" }}>
                  درحال حاضر این پکیج برای شما رزو شده است.
                </p>
              ) : (
                ""
              )}
              <button
                className="addToCart"
                onClick={() => addPlanHandler(plan)}
              >
                {plan.id === 1 && isAuthenticated
                  ? "برو به داشبورد"
                  : " خرید این پکیج"}
              </button>
              {/* <button className="addToCart" type="submit" onClick={handleSubmit}>خرید این پکیج</button> */}
            </Grid>
          </Grid>
          {/* </Formik> */}
        </main>
      </Layout>
    </>
  );
};

export default PlanPage;
