import { Box, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import jalaliMoment from "jalali-moment";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Navigation from "../../components/Navigation/Navigation.js";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const WeddingTodayPage = () => {
  const [todayWeddings, setTodayWeddings] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/today-weddings`)
      .then((response) => {
        const responseData = response?.data;
        setTodayWeddings(responseData);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  return (
    <>
      <Navigation />
      <main
        style={{
          paddingTop: "30px",
          backgroundColor: "#fff5f0",
          borderRadius: "15px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          maxWidth: "1200px",
          margin: "130px auto 40px",
        }}
      >
        <Grid container>
          <Grid item lg={12} xs={10}>
            <Typography variant="h3" style={{ textAlign: "center" }}>
              عروسی های امروز
            </Typography>{" "}
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {jalaliMoment(todayWeddings[0]?.date)
                ?.locale("fa")
                ?.format("dddd")}
            </Typography>
            <Typography variant="h6" style={{ textAlign: "center" }}>
              {todayWeddings[0]?.date}
            </Typography>
            <Box style={{ margin: "20px 0" }}>
              <TableContainer style={{ marginTop: "10px" }}>
                <Table className="tableMemento">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">#</TableCell>
                      <TableCell align="center">عروس</TableCell>
                      <TableCell align="center">داماد</TableCell>
                      <TableCell align="center">ساعت</TableCell>
                      <TableCell align="right">تالار</TableCell>
                      <TableCell align="center">sms</TableCell>
                      <TableCell align="center">پکیج</TableCell>
                      <TableCell align="center">app</TableCell>
                      <TableCell align="center">مهمان</TableCell>
                      <TableCell align="center">کارت</TableCell>
                      <TableCell align="center">بازدید</TableCell>
                      <TableCell align="center">کارت</TableCell>
                      <TableCell align="center">شماره</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {todayWeddings?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="right">{index +1}</TableCell>
                        <TableCell align="center">
                          <div>{item?.bride_name}</div>
                          <div>{item?.bride_instagram}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{item?.groom_name}</div>
                          <div>{item?.groom_instagram}</div>
                        </TableCell>
                        <TableCell align="center">
                          {item?.from_time?.substring(0, 5)} -{" "}
                          {item?.to_time?.substring(0, 5)}
                        </TableCell>
                        <TableCell align="right">
                          <div>{item?.province}</div>
                          <div>{item?.saloon_name}</div>
                          <div>{item?.saloon_address}</div>
                        </TableCell>
                        <TableCell align="center">
                          {item?.total_sms - item?.sms_left}
                        </TableCell>
                        <TableCell align="center">
                          {Number(item?.licence_id) === 1 ? (
                            <span style={{ color: "#CD7F32" }}>رایگان</span>
                          ) : (
                            <span style={{ color: "#ADD8E6" }}>لوکس</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {item?.balad ? (
                              <a href={item?.balad} target="_blank">
                                <img
                                  alt=""
                                  src="/asset/media/icon/balad.png"
                                  style={{ width: "20px" }}
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                            {item?.neshan ? (
                              <a href={item?.neshan} target="_blank">
                                <img
                                  alt=""
                                  src="/asset/media/icon/neshan.png"
                                  style={{ width: "20px" }}
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                            {item?.waze ? (
                              <a href={item?.waze} target="_blank">
                                <img
                                  alt=""
                                  src="/asset/media/icon/waze.png"
                                  style={{ width: "20px" }}
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                            {item?.google_map ? (
                              <a href={item?.google_map} target="_blank">
                                <img
                                  alt=""
                                  src="/asset/media/icon/google-map.png"
                                  style={{ width: "20px" }}
                                />
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {item?.carts_sum_number_of_guests}
                        </TableCell>
                        <TableCell align="center">
                          {item?.carts_count}
                        </TableCell>
                        <TableCell align="center">
                          {
                            item?.carts?.find((i) => Number(i.is_default) === 1)
                              ?.visit_count
                          }
                        </TableCell>
                        <TableCell align="center">
                          <a
                            alt="کارت"
                            href={`https://aroosi.online/${item?.carts[0]?.wedding_id}/${item?.carts[0]?.code}`}
                            target="_blank" rel="noreferrer"
                          >
                            <img
                              src="/asset/media/icon/show-card.png"
                              alt=""
                              style={{ width: "30px" }}
                            />
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          {item?.client?.mobile}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item lg={4} xs={11}>
            <Box>
              <Typography></Typography>
            </Box>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default WeddingTodayPage;
