import { Box, Typography, Grid } from "@mui/material";
import Axios from "axios";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../auth/authContext";
import { Link } from "react-router-dom";
import DisableLicence from "../../../common/DisableLicence";
import { CircularProgress } from "@material-ui/core";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const MementoPage = () => {
  const { token, cartData } = useAuth();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [comment, setComment] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const toggleRowSelection = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
      setSelectAll(false);
    } else {
      const updatedRows = [...selectedRows, id];
      setSelectedRows(updatedRows);
      if (updatedRows.length === comment?.length) {
        setSelectAll(true);
      }
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds = comment?.map((item) => item.id) || [];
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setIsloading(true);
    Axios.get(`${BASE_URL}/api/licence/current`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        const responseData = response?.data?.wedding?.mementos || [];
        const updatedSelectedRows = responseData
          ?.filter((item) => Number(item?.show))
          ?.map((item) => item?.id);
        setComment(responseData);
        setSelectedRows(updatedSelectedRows);
        setSelectAll(
          updatedSelectedRows.length === responseData.length &&
            responseData.length
        );
        toast.success(response?.data?.message);
        setIsloading(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setIsloading(false);
      });
  }, []);

  const handleSubmit = (values) => {
    axios
      .post(
        `${BASE_URL}/api/memento/edit`,
        {
          mementos: selectedRows,
          show: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Box
      style={{
        width: "1000px",
        maxWidth: "100%",
        margin: "0 auto",
        position: "relative",
      }}
    >
      {Number(cartData?.licence_id) === 1 ? <DisableLicence /> : ""}
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={12} style={{ paddingLeft: 0, marginBottom: "80px" }}>
          <Typography variant="h5" sx={{ mb: 1, mt: 2, color: "#40407a" }}>
            یادبودها
          </Typography>
          <Typography variant="p" sx={{ mt: 1 }}>
            با فعال کردن گزینه <span className="square"></span> و ذخیره تغیرات
            مشخص کنید کدام یادبودها در کارت شما، برای همه مهمان‌ها نمایش داده
            شود.
          </Typography>

          <TableContainer component={Paper} style={{ marginTop: "10px" }}>
            <Table className="tableMemento">
              <TableHead>
                <TableRow>
                  <TableCell align="right">#</TableCell>
                  <TableCell align="right">نام</TableCell>
                  <TableCell align="right">موبایل</TableCell>
                  <TableCell align="right" style={{ width: "50%" }}>
                    پیام
                  </TableCell>
                  {/* <TableCell align="right">حضور</TableCell> */}
                  <TableCell
                    align="right"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox checked={selectAll} onChange={toggleSelectAll} />
                  </TableCell>
                </TableRow>
              </TableHead>
              {isloading ? (
                <CircularProgress />
              ) : !comment?.length ? (
                <Typography
                  style={{
                    padding: "20px 10px",
                    color: "#a29bfe",
                    minWidth: "250px",
                  }}
                >
                  در حال حاضر یادبودی ثبت نشده است.
                </Typography>
              ) : (
                <TableBody>
                  {comment?.map((comment, index) => (
                    <TableRow key={index}>
                      <TableCell align="right">{index + 1}</TableCell>
                      <TableCell align="right">{comment?.cart?.name}</TableCell>
                      <TableCell align="right">
                        {comment?.cart?.mobile}
                      </TableCell>
                      <TableCell align="right">
                        {comment?.name ? (
                          <h6 style={{ color: "#a29bfe" }}>{comment?.name}:</h6>
                        ) : null}
                        <p>{comment?.text}</p>
                      </TableCell>
                      <TableCell align="left">
                        <Checkbox
                          checked={selectedRows.includes(comment?.id)}
                          onChange={() => toggleRowSelection(comment?.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* )}  */}
        </Grid>
      </Grid>

      <button
        className="saveInformation chageBtnComment"
        type="submit"
        onClick={handleSubmit}
      >
        ذخیره تغیرات
      </button>
    </Box>
  );
};

export default MementoPage;
