import React from "react";
import { useAuth } from "../../auth/authContext";
import ChangeMusic from "../../common/ChangeMusic";
import Timeline from "./common/Timeline";


const events = [
  { year: "2002", title: "Title 1", description: "Lorem ipsum..."},
  { year: "2007", title: "Title 2", description: "Lorem ipsum..." },
  { year: "2007", title: "Title 2", description: "Lorem ipsum..."},
  { year: "2007", title: "Title 2", description: "Lorem ipsum..." },
];

const CardPageTemplate = ({ sections, className }) => {
  const { cartData } = useAuth();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isSectionVisible = (sectionId) => {
    switch (sectionId) {
      case "section3": // یادبودها
        return Number(cartData?.show_memento);
      case "section7": // نظرسنجی
        return Number(cartData?.show_survey);
      case "section6": // بخش ۶
        return Number(cartData?.show_survey);
      default:
        return true; // پیش‌فرض نمایش
    }
  };

  // ایجاد عناصر ناوبری
  const renderNavItems = (isMobile = false) => (
    <nav className={isMobile ? "sidebarCardMobile" : "sidebarCart"}>
      <ul>
        {sections
          ?.filter((section) => section?.showInNav)
          ?.map(
            (section) =>
              isSectionVisible(section.id) ? (
                <li
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                >
                  {section.icon}
                  {/* {section.label} */}
                </li>
              ) : null // اگر شرط برقرار نبود، هیچ عنصری رندر نشود
          )}
      </ul>
    </nav>
  );

  return (
    <div className={`cardPage ${className || ""}`}>
      {/* ناوبری */}
      {renderNavItems()}
      {renderNavItems(true)}

      {/* محتوا */}
      <main>
        {isSectionVisible("section4") ? (
          <section id="section4">
            {React.createElement(
              sections?.find((sec) => sec.id === "section4")?.component,
              {
                cartData: cartData,
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section1") ? (
          <section id="section1">
            {React.createElement(
              sections?.find((sec) => sec.id === "section1")?.component,
              {
                cartData: cartData,
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section2") ? (
          <section id="section2">
            {React.createElement(
              sections?.find((sec) => sec.id === "section2")?.component,
              {
                cartData: cartData,
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section3") ? (
          <section id="section3">
            {React.createElement(
              sections?.find((sec) => sec.id === "section3")?.component,
              {
                cartData: null, // شرط خاص برای section3
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section7") ? (
          <section id="section7">
            {React.createElement(
              sections?.find((sec) => sec.id === "section7")?.component,
              {
                cartData: cartData, // شرط خاص برای section7
              }
            )}
          </section>
        ) : null}

        {/* <section>
        <Timeline events={events} color="#41516C"/>
        </section> */}

        {isSectionVisible("section6") ? (
          <section id="section6">
            {React.createElement(
              sections?.find((sec) => sec.id === "section6")?.component,
              {
                cartData: cartData,
              }
            )}
          </section>
        ) : null}
      </main>

      {/* موسیقی */}
      <ChangeMusic music={cartData?.music} />
    </div>
  );
};

export default CardPageTemplate;
