import React from "react";
import { useAuth } from "../../auth/authContext";
import ChangeMusic from "../../common/ChangeMusic";
import MakeWithLove from "./common/MakeWithLove";

const CardPageTemplateGuest = ({ guestCart, sections, className }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isSectionVisible = (sectionId) => {
    switch (sectionId) {
      case "section3": // یادبودها
        return (
          Number(guestCart?.wedding?.show_memento) &&
          !Number(guestCart?.is_default)
        );
      case "section7": // نظرسنجی
        return (
          Number(guestCart?.wedding?.show_survey) &&
          !Number(guestCart?.is_default)
        );
      case "section6": // بخش ۶
        return Number(guestCart?.wedding?.show_survey);
      default:
        return true; // پیش‌فرض نمایش
    }
  };

  // ایجاد عناصر ناوبری
  const renderNavItems = (isMobile = false) => (
    <nav className={isMobile ? "sidebarCardMobile" : "sidebarCart"}>
      <ul>
        {sections
          ?.filter((section) => section?.showInNav)
          ?.map(
            (section) =>
              isSectionVisible(section.id) ? (
                <li
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                >
                  {/* {section.label} */}
                  {section.icon}
                </li>
              ) : null // اگر شرط برقرار نبود، هیچ عنصری رندر نشود
          )}
      </ul>
    </nav>
  );

  return (
    <div className={`cardPage ${className || ""}`}>
      {/* ناوبری */}
      {renderNavItems()}
      {renderNavItems(true)}

      {/* محتوا */}
      <main>
        {guestCart?.name && <div className="guestName">{guestCart?.name}</div>}

        {isSectionVisible("section4") ? (
          <section id="section4">
            {React.createElement(
              sections?.find((sec) => sec.id === "section4")?.component,
              {
                cartData: guestCart?.wedding,
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section1") ? (
          <section id="section1">
            {React.createElement(
              sections?.find((sec) => sec.id === "section1")?.component,
              {
                cartData: guestCart?.wedding,
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section2") ? (
          <section id="section2">
            {React.createElement(
              sections?.find((sec) => sec.id === "section2")?.component,
              {
                cartData: guestCart?.wedding,
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section3") ? (
          <section id="section3">
            {React.createElement(
              sections?.find((sec) => sec.id === "section3")?.component,
              {
                cartData: guestCart, // شرط خاص برای section3
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section7") ? (
          <section id="section7">
            {React.createElement(
              sections?.find((sec) => sec.id === "section7")?.component,
              {
                cartData: guestCart, // شرط خاص برای section7
              }
            )}
          </section>
        ) : null}

        {isSectionVisible("section6") ? (
          <section id="section6">
            {React.createElement(
              sections?.find((sec) => sec.id === "section6")?.component,
              {
                cartData: guestCart?.wedding,
              }
            )}
          </section>
        ) : null}

        <MakeWithLove />
      </main>

      {/* موسیقی */}
      <ChangeMusic music={guestCart?.wedding?.music} />
    </div>
  );
};

export default CardPageTemplateGuest;
