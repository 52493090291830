import CardPage from "./Card/CardPage";
import CardPage5 from "./Card5/CardPage5";
import CardPage6 from "./Card6/CardPage6";
import CardPage7 from "./Card7/CardPage7";
import CardPage8 from "./Card8/CardPage8";
import CardPage3 from "./Card3/CardPage3";
import CircularProgress from "@mui/material/CircularProgress";
import CardPage2 from "./Card2/CardPage2";
import CardPage4 from "./Card4/CardPage4";
import { useAuth } from "../../auth/authContext";
import { Helmet } from "react-helmet-async";
import CardPage9 from "./Card9/CardPage9";
import CardPage10 from "./Card10/CardPage10";

const ShowMyCardPage = () => {
  const { cartData } = useAuth();

  const renderComponent = () => {
    switch (Number(cartData?.template_id)) {
      case 1:
        return <CardPage />;
      case 2:
        return <CardPage2 />;
      case 3:
        return <CardPage3 />;
      case 4:
        return <CardPage4 />;
      case 5:
        return <CardPage5 />;
      case 6:
        return <CardPage6 />;
      case 7:
        return <CardPage7 />;
      case 8:
        return <CardPage8 />;
      case 9:
        return <CardPage9 />;
      case 10:
        return <CardPage10 />;
      default:
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>عروسی آنلاین - نمایش کارت من</title>
        <meta name="description" content="نمایش کارت من" />
      </Helmet>
      {renderComponent()}
    </>
  );
};

export default ShowMyCardPage;
