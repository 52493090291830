import "./CardPage8.css";
import Section1Page from "./components/Section1";
import Section2Page from "./components/Section2";
import Section3Page from "./components/Section3";
import Section4Page from "./components/section4";
import Section7Page from "./components/Section7";
import Section6Page from "./components/Section6";
import CardPageTemplate from "../CardPageTemp";
import CardPageTemplateGuest from "../CardPageTempGuest";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import HowToRegIcon from "@mui/icons-material/HowToReg";

const CardPageGuest8 = ({ guestCart }) => {
  const sections = [
    {
      id: "section4",
      label: "زمان",
      component: Section4Page,
      icon: <AccessTimeIcon fontSize="large" />,
      showInNav: true,
    },
    {
      id: "section1",
      label: "کارت",
      component: Section1Page,
      icon: <HistoryEduIcon fontSize="large" />,
      showInNav: true,
    },
    {
      id: "section2",
      label: "لوکیشن",
      component: Section2Page,
      icon: <LocationOnIcon fontSize="large" />,
      showInNav: true,
    },
    {
      id: "section3",
      label: "یادبود",
      component: Section3Page,
      icon: <ChatBubbleOutlineIcon fontSize="large" />,
      showInNav: true,
    },
    {
      id: "section7",
      label: "نظرسنجی",
      component: Section7Page,
      icon: <HowToRegIcon fontSize="large" />,
      showInNav: true,
    },
    {
      id: "section6",
      label: "",
      component: Section6Page,
      icon: <></>,
      showInNav: false,
    },
  ];

  return (
    <CardPageTemplateGuest
      guestCart={guestCart}
      sections={sections}
      className="cardPage8"
    />
  );
};

export default CardPageGuest8;
