import React from "react";

const CarouselHome = () => {
  return (
    <div className="wrapper-org">
      <div className="carousel-org">
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/add-card.png" />
          اضافه کردن کارت و مهمان به صورت نامحدود
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/location.png" />
          امکان استفاده از اپ های مسیریابی (ویز٫ گوگل مپ٫ بلد و نشان)
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/seen.png" />
          ارسال تمامی کارت‌ها با یک کلیک و اطمینان از بازدید کارت توسط مهمان
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/survey.png" />
          مدیریت هزینه ها با استفاده از نظرسنجی شرکت یا عدم شرکت هر مهمان
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/sms2.png" />
          پیامک های متنوع مثل یادآوری مراسم٫ رزرو پارکینگ و تشکر حضور در مراسم
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/picture.png" />
          امکان قرار دادن عکس‌ در پس زمینه کارت یا به صورت تکی
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/music2.png" />
          پخش موسیقی دلخواه شما در پس زمینه کارت دعوت
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/chart.png" />
          آمار دقیق از مراسم(تعداد مهمان‌ها و کارت‌ها و روزهای باقی مانده)
        </div>
        <div className="carousel__item">
          <img alt="icon" src="/asset/media/icon/memento.png" />
          امکان ثبت یادبود توسط مهمان‌ها
        </div>
      </div>
    </div>
  );
};

export default CarouselHome;
