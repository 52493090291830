import React from "react";
import Layout from "../../Layout/Layout";
import { Box, Grid, Typography } from "@mui/material";
import "../Bloges/blogs.css";
import { Link } from "react-router-dom";

const Blog2 = () => {
  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "900px",
          margin: "100px auto",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          borderRadius: "10px",
          padding: "60px 25px",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={{ fontSize: "32px" }}>
              چک لیست کامل برای برنامه‌ریزی عروسی در سال ۱۴۰۴
            </Typography>
            <img
              alt="چک لیست کامل برای برنامه‌ریزی عروسی"
              src="/asset/media/blog/wedding1.jpeg"
              style={{ width: "100%", borderRadius: "15px", margin: "20px 0" }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              برنامه‌ریزی برای عروسی یکی از هیجان‌انگیزترین مراحل زندگی هر زوج
              است، اما اگر بدون برنامه‌ریزی دقیق باشد، ممکن است به استرس و
              سردرگمی منجر شود. در این مقاله، چک لیستی کامل و کاربردی برای
              برنامه‌ریزی عروسی در سال ۱۴۰۴ آماده کرده‌ایم که به شما کمک می‌کند
              قدم‌به‌قدم و بدون نگرانی، مراسم رویایی خود را برگزار کنید.
            </Typography>

            {/* number 1 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۱. تعیین تاریخ عروسی
            </Typography>
            <Typography variant="body1">
              یکی از اولین تصمیماتی که باید بگیرید، تعیین تاریخ عروسی است. تاریخ
              مناسبی را انتخاب کنید که به شما و مهمانانتان فرصت کافی برای
              آماده‌سازی بدهد. به موارد زیر توجه کنید:
            </Typography>
            <ul className="listBlog">
              <li>تعطیلات رسمی و مناسبت‌های خاص</li>
              <li>شرایط آب‌وهوا</li>
              <li>هماهنگی با تالار یا باغ موردنظر</li>
            </ul>
            <Typography variant="body1">
              نکته: بهتر است حداقل ۶ ماه قبل از تاریخ عروسی تصمیم‌گیری کنید تا
              زمان کافی برای برنامه‌ریزی داشته باشید.
            </Typography>

            {/* number 2 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۲. تعیین بودجه
            </Typography>
            <Typography variant="body1">
              بودجه یکی از مهم‌ترین عوامل در برنامه‌ریزی عروسی است. مشخص کنید چه
              مقدار می‌خواهید خرج کنید و این مبلغ را به بخش‌های مختلف مثل تالار،
              لباس، گل‌آرایی و ... تقسیم کنید.
            </Typography>

            <Typography
              variant="h5"
              sx={{ fontSize: "18px", margin: "20px 0 10px" }}
            >
              هزینه‌های اصلی:
            </Typography>
            <ul className="listBlog">
              <li>مکان برگزاری (تالار یا باغ)</li>
              <li>پذیرایی و غذا</li>
              <li>لباس عروس و داماد</li>
              <li>عکاسی و فیلم‌برداری</li>
            </ul>
            <Typography
              variant="h5"
              sx={{ fontSize: "18px", margin: "20px 0 10px" }}
            >
              هزینه‌های جانبی:
            </Typography>
            <ul className="listBlog">
              <li>دکوراسیون و گل‌آرایی</li>
              <li>کارت عروسی (دیجیتال یا کاغذی)</li>
              <li>موزیک و سرگرمی</li>
              <li>هدیه یادبود برای مهمانان</li>
            </ul>

            <Typography variant="body1">
              نکته: برای موارد غیرمنتظره حتماً بودجه‌ای اضافی در نظر بگیرید.
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="عروسی"
                src="/asset/media/blog/wedding2.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number3 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۳. رزرو مکان برگزاری
            </Typography>
            <Typography variant="body1">
              پس از تعیین بودجه و تاریخ، نوبت به رزرو مکان می‌رسد. بسته به تعداد
              مهمانان و سبک مراسم، تالار یا باغ مناسب را انتخاب کنید. هنگام
              بازدید از مکان‌ها، به موارد زیر توجه کنید:
            </Typography>
            <ul className="listBlog">
              <li>ظرفیت سالن</li>
              <li>امکانات پارکینگ</li>
              <li>شرایط پذیرایی و کیفیت خدمات</li>
            </ul>
            <Typography variant="body1">
              نکته: اگر به دنبال برگزاری مراسم در فضای باز هستید، حتماً به
              تجهیزات لازم برای شرایط آب‌وهوایی غیرمنتظره توجه کنید.
            </Typography>

            {/* number 4 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۴. طراحی و ارسال کارت عروسی
            </Typography>
            <Typography variant="body1">
              یکی از بخش‌های هیجان‌انگیز، انتخاب کارت عروسی است. در سال اخیر،
              استفاده از کارت‌های دیجیتال به دلیل سهولت و سازگاری با محیط زیست
              بسیار پرطرفدار است.
            </Typography>
            <Typography variant="body1">
              <Link to="/" style={{ color: "red" }}>
                {" "}
                عروسی آنلاین{" "}
              </Link>
              تخصصی ترین سایت کارت دیجتال عروسی هست که کارت هامون قابلیت های
              زیادی داره مثل:
            </Typography>
            <ul className="listBlog">
              <li>امکان ارسال sms دعوت به شماره مهمان‌ها تنها با یک کلیک</li>
              <li>
                ارسال sms یادآوری روز مراسم، تشکر برای حضور در مراسم، رزرو
                پارکینگ برای مهمان‌های نزدیک و ...
              </li>
              <li>
                امکان استفاده از برنامه های مسیریابی مثل ویز، گوگل مپ، نشان و
                بلد
              </li>
              <li>
                ساخت انواع نظرسنجی مثل نظرسنجی حضور در مراسم برای مدیریت تعداد
                مهمان‌ها و هزینه
              </li>
              <li>امکان ثبت یادبود و پیام تبریک توسط مهمان‌ها</li>
              <li>پخش موزیک در پس زمینه کارت</li>
              <li>
                نمایش عکس دلخواه در پس زمینه کارت یا عکس عروس داماد به صورت تکی
              </li>
              <li>امکان انتخاب قالب های متنوع</li>
              <li>
                امار و ارقام دقیق از مراسم مثل تعداد مهمان و کارت ها، تعداد
                مهمان هایی که در مراسم شرکت میکنند و...
              </li>
            </ul>
            <Link to="/example-card" style={{ color: "red" }}>
              {" "}
              برای دیدن نمونه کارت ها کلیک کنید{" "}
            </Link>
            <Typography variant="body1">
              نکته: کارت عروسی دیجیتال علاوه بر صرفه‌جویی در هزینه، تجربه‌ای
              مدرن و جذاب برای مهمانان فراهم می‌کند.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="عروسی"
                src="/asset/media/blog/wedding3.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 5 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۵. لباس عروسی
            </Typography>
            <Typography variant="body1">
              لباس عروس و داماد یکی از مهم‌ترین بخش‌های مراسم است. برای انتخاب
              لباس مناسب:
            </Typography>
            <ul className="listBlog">
              <li>سبک و تم مراسم را در نظر بگیرید</li>
              <li>به تناسب فصل و آب‌وهوا توجه کنید</li>
              <li>حداقل ۳ ماه قبل از مراسم، لباس خود را سفارش دهید</li>
            </ul>
            <Typography variant="body1">
              نکته: برای امتحان نهایی لباس، زمانی نزدیک به مراسم تعیین کنید تا
              تغییرات لازم انجام شود.
            </Typography>

            {/* number 6 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۶. هماهنگی با عکاس و فیلم‌بردار
            </Typography>
            <Typography variant="body1">
              انتخاب عکاس و فیلم‌بردار حرفه‌ای اهمیت زیادی دارد، زیرا خاطرات
              مراسم شما را ثبت می‌کنند. هنگام انتخاب:
            </Typography>
            <ul className="listBlog">
              <li>نمونه کارهای قبلی را بررسی کنید</li>
              <li>به سبک عکاسی و فیلم‌برداری علاقه‌مند باشید</li>
              <li>قرارداد رسمی با جزئیات کامل تنظیم کنید</li>
            </ul>
            <Typography variant="body1">
              نکته: از عکاس بخواهید چند جلسه پیش از مراسم برای آشنایی با شما و
              لوکیشن‌ها برنامه‌ریزی کند.
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="اتلیه عکس و فیلم عروسی"
                src="/asset/media/blog/wedding4.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 7 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۷. تنظیم برنامه مراسم
            </Typography>
            <Typography variant="body1">
              برای اینکه مراسم بدون مشکل پیش برود، یک برنامه دقیق تهیه کنید. این
              برنامه می‌تواند شامل موارد زیر باشد:
            </Typography>
            <ul className="listBlog">
              <li>زمان شروع و پایان مراسم</li>
              <li>برنامه اجرای موزیک و سرگرمی</li>
              <li>زمان برش کیک و شام</li>
            </ul>
            <Typography variant="body1">
              نکته: این برنامه را با مدیر تالار و تیم خدماتی هماهنگ کنید.
            </Typography>

            {/* nummber 8 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۸. انتخاب گروه موزیک مناسب
            </Typography>
            <Typography variant="body1">
              موزیک و گروه موسیقی خوب نقش بزرگی در شادی مهمانان دارد. با گروه
              موسیقی یا دی‌جی حرفه‌ای قرارداد ببندید و لیستی از آهنگ‌های
              موردعلاقه خود را تهیه کنید.
            </Typography>

            <Typography variant="body1">
              ایده: می‌توانید از اجرای زنده، نمایش‌های هنری یا حتی بازی‌های
              تعاملی برای جذاب‌تر کردن مراسم استفاده کنید.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="دیجی عروسی"
                src="/asset/media/blog/wedding6.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 9  */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۹. گل‌آرایی و دکوراسیون
            </Typography>
            <Typography variant="body1">
              گل‌آرایی و دکوراسیون تأثیر زیادی در زیبایی مراسم شما دارد. برای
              این بخش:
            </Typography>
            <ul className="listBlog">
              <li>تم رنگی مراسم را مشخص کنید</li>
              <li>از گل‌های فصل استفاده کنید</li>
              <li>از تیم حرفه‌ای برای اجرا کمک بگیرید</li>
            </ul>
            <Typography variant="body1">
              نکته: اگر مراسم شما در فضای باز است، به نورپردازی مناسب توجه ویژه
              داشته باشید.
            </Typography>

            {/* number 10 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۱۰. برنامه‌ریزی برای ماه عسل
            </Typography>
            <Typography variant="body1">
              پس از مراسم، ماه عسل فرصتی برای استراحت و لذت بردن از شروع زندگی
              مشترک است. مقصد موردنظر خود را انتخاب کنید و از قبل رزروهای لازم
              را انجام دهید.
            </Typography>

            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img
                  alt="ماه عسل"
                  src="/asset/media/blog/wedding5.jpeg"
                  style={{
                    width: "80%",
                    borderRadius: "15px",
                    margin: "20px auto",
                  }}
                />
              </Box>
              جمع‌بندی
            </Typography>
            <Typography variant="body1">
              برنامه‌ریزی عروسی می‌تواند پیچیده باشد، اما با داشتن یک چک لیست
              کامل و دقیق، می‌توانید این مسیر را با آرامش و اطمینان طی کنید. با
              رعایت نکات بالا، مراسم شما نه تنها به‌یادماندنی، بلکه بدون استرس
              برگزار خواهد شد. اگر به دنبال کارت عروسی دیجیتال برای مراسم خود
              هستید، همین حالا از خدمات سایت ما استفاده کنید و تجربه‌ای متفاوت
              را رقم بزنید!
            </Typography>
          </Grid>

          <Typography
            variant="h3"
            sx={{ fontSize: "24px", margin: "20px 0 10px" }}
          ></Typography>
          <Typography variant="body1"></Typography>
          <ul className="listBlog">
            <li></li>
            <li></li>
            <li></li>
          </ul>
          <Typography variant="body1"></Typography>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Blog2;
