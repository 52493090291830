import Layout from "../Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import TypingComponent from "../common/typingComponent";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCartActions } from "../Providers/CartProvider";
import { useAuth } from "../auth/authContext";
import * as data from "../data.js";
import numberToPersian from "../common/numberToPersian.js";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const OffPage = () => {
  const options = [
    " ارسال sms دعوت به شماره تماس مهمان ها (۳۰۰ پیامک رایگان)",
    "ارسال sms یاد اوری روز مراسم، تشکر برای حضور در مراسم، رزرو شدن پارکینگ برای مهمان های خاص و...",
    "امکان ثبت ادرس تالار در ویز، نشان، بلد و گوگل مپ برای مهمان‌ها",
    "ساخت انواع نظر سنجی مثلا نظرسنجی شرکت در مراسم",
    "آمار دقیق نظرسنجی ها به همراه نمودار مثلا چند درصد و چه کسانی در مراسم شرکت نمیکنند",
    "امکان ثبت یادبود و پیام تبریک مهمان‌ها برای شما",
    "اطلاع رسانی به شما از طریق پیامک در صورت ثبت یادبود مهمان ها",
    "نمایش یادبودهای مهمان ها در کارت",
    "امکان پخش موزیک در پس‌زمینه کارت",
    "امکان نمایش عکس عروس داماد به صورت تکی یا در پس زمینه کارت",
    "امکان انتخاب قالب‌های کارت متنوع",
    "آمار و ارقام دقیق از مراسم مثل تعداد مهمان‌ها و کارت و روزهای باقی مانده تا مراسم",
    "با یک بار خرید پکیج  محدودیتی در افزودن مهمان و کارت ندارید",
  ];

  const typedStrings = [
    "الان بگیر هروقت خواستی استفاده کن",
    "تخفیف استثنایی عروسی آنلاین",
    "بیشترین تخفیف قبل از افزایش قیمت سال جدید",
    "بیش از ۵۰٪ تخفیف روی پکیج لوکس",
  ];

  // const plan = data.plans.find((plan) => plan.id == planId);
  const [offDetails, setOffDetails] = useState(null);
  const navigate = useNavigate();
  const dispatch = useCartActions();
  const { token, isAuthenticated } = useAuth();

  const CopyLink = () => {
    const link = offDetails?.code;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.info("لینک با موفقیت کپی شد!");
      })
      .catch((err) => {
        toast.error("خطا در کپی کردن لینک!");
        console.error("Clipboard copy failed: ", err);
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/nowrouz-1404`)
      .then((response) => {
        setOffDetails(response?.data);
      })
      .catch((error) => {});
  }, []);

  const addPlanHandler = (plan) => {
    if (!isAuthenticated) {
      if (plan.id === 1) {
        navigate("/login", { state: { redirectPath: "/dashboard" } });
      } else {
        navigate("/login", { state: { redirectPath: "/cart-off" } });
        return;
      }
    } else {
      if (plan.id === 1) {
        navigate("/dashboard", { replace: false });
      } else {
        dispatch({ type: "ADD_TO_CART", payload: plan });
        navigate("/cart-off", { replace: false });
      }
    }
  };

  return (
    <>
      <Layout>
        <section className="" style={{ padding: "30px 0", margin: "40px 0" }}>
          <div style={{ width: "300px", margin: "0 auto" }}>
            <img
              alt=""
              src="/asset/media/icon/off.png"
              style={{ width: "100%" }}
            />
          </div>

          <Grid container justifyContent="center" alignItems="center">
            <Grid item lg={7} xs={11}>
              <Box style={{ height: "200px", color: "red" }}>
                <TypingComponent strings={typedStrings} />
              </Box>
            </Grid>

            <Grid item lg={6} xs={11}>
              <Typography
                variant="h4"
                style={{ marginBottom: "20px", color: "#2c2c54" }}
              >
                امکانات پکیج لوکس:
              </Typography>
              {options?.map((option, index) => (
                <Box
                  key={option.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    flexGrow: "none",
                  }}
                >
                  <div style={{ width: "40px", height: "40px", flexShrink: 0 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      width="100px"
                      height="100px"
                      baseProfile="basic"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <path
                        fill="#98c900"
                        d="M54,20c0-5.523-4.477-10-10-10H32H20c-5.523,0-10,4.477-10,10v24.008c0,5.523,4.477,10,10,10h12h12 c5.523,0,10-4.477,10-10V20z"
                      />
                      <ellipse cx="32" cy="61" opacity=".3" rx="20" ry="3" />
                      <path
                        fill="#fff"
                        d="M14.01,12H14c-2.24,1.69-3.75,4.29-3.97,7.25C10.01,19.49,10,19.75,10,20v12 c2.761,0,5-2.239,5-5v-7c0-0.108,0.003-0.221,0.017-0.38c0.102-1.375,0.778-2.65,1.862-3.525c0.048-0.033,0.095-0.068,0.142-0.103 C17.881,15.343,18.911,15,20,15h5c2.761,0,5-2.239,5-5H20C17.75,10,15.68,10.74,14.01,12z"
                        opacity=".3"
                      />
                      <path
                        d="M54,44V22c-2.761,0-5,2.238-5,5v17c0,2.757-2.243,5-5,5h-5c-2.761,0-5,2.238-5,5h10 C49.523,54,54,49.523,54,44z"
                        opacity=".15"
                      />
                      <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="10"
                        stroke-width="3"
                        d="M13.5,23.5V20c0-0.153,0.005-0.312,0.018-0.459c0.135-1.809,1.003-3.46,2.396-4.594l0.204-0.152"
                      />
                      <path
                        fill="#edff9c"
                        d="M26.727,42.104l-8.43-8.457c-0.397-0.398-0.397-1.044,0-1.442l2.875-2.884 c0.397-0.398,1.041-0.398,1.438,0l6.135,6.154l12.428-14.13c0.371-0.422,1.014-0.463,1.435-0.09l3.049,2.699 c0.421,0.373,0.461,1.017,0.09,1.439L31.17,41.965C30.007,43.288,27.971,43.352,26.727,42.104z"
                      />
                    </svg>
                  </div>

                  <p style={{ color: "#2c2c54" }}>{option}</p>
                </Box>
              ))}
            </Grid>

            <Grid item lg={7} xs={11} style={{ margin: "50px 0" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "17px",
                }}
              >
                <img
                  alt=""
                  src="/asset/media/icon/warn.png"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "10px",
                  }}
                />
                <Typography variant="" style={{ color: "#2c2c54" }}>
                  اگر مراسم عقد و عروسی و... شما در سال آینده (۱۴۰۴) است این بهترین
                  فرصت برای خرید کارت عروسی آنلاین است.
                </Typography>
              </Box>

              <Box style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt=""
                  src="/asset/media/icon/warn.png"
                  style={{
                    width: "40px",
                    height: "40px",
                    marginLeft: "10px",
                  }}
                />
                <Typography variant="" style={{ color: "#2c2c54" }}>
                  با ایجاد نظرسنجی برای حضور یا عدم حضور مهمان‌ها، میتونید مهمان
                  جدید جایگزین کنید که با این کار چندین برابر هزینه کارت رو به
                  دست میارید
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={7} xs={11}>
              <Box
                style={{
                  border: "3px solid red",
                  padding: " 0 30px 30px",
                  borderRadius: "15px",
                  marginBottom: "30px",
                }}
              >
                <div style={{ width: "160px", margin: "0 auto" }}>
                  <img
                    alt=""
                    src="/asset/media/icon/off2.png"
                    style={{ width: "100%" }}
                  />
                </div>
                <Box
                  style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}
                >
                  <Typography variant="">
                    کد تخفیف :
                    <span style={{ color: "#54a0ff", marginRight: "10px" }}>
                      {offDetails?.code}
                    </span>{" "}
                  </Typography>
                  <button
                    style={{
                      padding: "2px 10px",
                      border: "1px solid #54a0ff",
                      color: "#54a0ff",
                      backgroundColor: "transparent",
                      borderRadius: "3px",
                    }}
                    onClick={CopyLink}
                  >
                    کپی
                  </button>
                </Box>
                <Typography variant="body1">
                  تنها{" "}
                  <span
                    style={{ color: "red", fontSize: "20px", padding: "0 5px" }}
                  >
                    ۱۰۰
                  </span>{" "}
                  نفر میتوانند از این کد تخفیف استفاده کنند.{" "}
                </Typography>
                <Typography variant="body1">
                  باقی مانده :{" "}
                  <span
                    style={{ color: "red", fontSize: "20px", padding: "0 5px" }}
                  >
                    {numberToPersian(offDetails?.count)}
                  </span>{" "}
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={9} xs={11} className="text-center">
              {/* <Link to="/plan/2" className="offPackageBtn">
                خرید پکیج
              </Link> */}
              <button
                className="offPackageBtn"
                onClick={() => addPlanHandler(data?.plans[1])}
              >
                خرید پکیج
              </button>
              <Link to="/example-card" className="showExampleCard">
                دیدن نمونه کارت
              </Link>
            </Grid>
            <Grid item lg={9} xs={11} style={{justifyContent:"center",display:'flex',marginTop:"30px"}}>
              <div className="support" style={{ width: "130px" }}>
                <a href="https://t.me/aroosiOnline" target="_blank">
                  <span>پشتیبانی</span>
                  <img src="/asset/media/pic/icon/support.png" />
                </a>
              </div>
            </Grid>
          </Grid>

          {/* <h2 className="aroosiOnline">aroosi.online</h2> */}
        </section>
      </Layout>
    </>
  );
};

export default OffPage;
