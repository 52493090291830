import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../auth/authContext";
import CardPageGuest from "./Card/CardPageGuest";
import CardPageGuest2 from "./Card2/CardPageGuest2";
import CardPageGuest3 from "./Card3/CardPageGuest3";
import CardPageGuest4 from "./Card4/CardPageGuest4";
import CardPageGuest5 from "./Card5/CardPageGuest5";
import CardPageGuest6 from "./Card6/CardPageGuest6";
import CardPageGuest7 from "./Card7/CardPageGuest7";
import CardPageGuest8 from "./Card8/CardPageGuest8";
import { Helmet } from "react-helmet-async";
import CardPageGuest9 from "./Card9/CardPageGuest9";
import CardPageGuest10 from "./Card10/CardPageGuest10";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const GuestCardPage = () => {
  const { username, parameter } = useParams();
  const [guestCart, setGuestCart] = useState(null);
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {};
        const response = await Axios.get(
          `${BASE_URL}/api/cart/${username}/${parameter}`,
          config
        );
        setGuestCart(response?.data?.cart);
      } catch (error) {
        if (error.response?.status === 404) {
          navigate("/notfound"); // هدایت به صفحه NotFound
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [username, parameter, token, navigate]);
  const renderComponent = () => {
    switch (Number(guestCart?.wedding?.template_id)) {
      case 1:
        return <CardPageGuest guestCart={guestCart} />;
      case 2:
        return <CardPageGuest2 guestCart={guestCart} />;
      case 3:
        return <CardPageGuest3 guestCart={guestCart} />;
      case 4:
        return <CardPageGuest4 guestCart={guestCart} />;
      case 5:
        return <CardPageGuest5 guestCart={guestCart} />;
      case 6:
        return <CardPageGuest6 guestCart={guestCart} />;
      case 7:
        return <CardPageGuest7 guestCart={guestCart} />;
      case 8:
        return <CardPageGuest8 guestCart={guestCart} />;
      case 9:
        return <CardPageGuest9 guestCart={guestCart} />;
      case 10:
        return <CardPageGuest10 guestCart={guestCart} />;
      default:
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>عروسی آنلاین</title>
        <meta
          name="description"
          content={`
           کارت عروسی ${guestCart?.bride_name} و ${guestCart?.groom_name} ${
            guestCart?.is_default
              ? "کارت دعوت شما"
              : `کارت اختصاصی ${guestCart?.name}`
          }
          `}
        />
      </Helmet>

      {renderComponent()}
    </>
  );
};

export default GuestCardPage;
