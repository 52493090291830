import React from "react";
import Layout from "../../Layout/Layout";
import { Box, Grid, Typography } from "@mui/material";
import "../Bloges/blogs.css";
import { Link } from "react-router-dom";

const Blog1 = () => {
  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "900px",
          margin: "100px auto",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          borderRadius: "10px",
          padding: "60px 25px",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={{ fontSize: "32px" }}>
              مزایای کارت عروسی دیجیتال نسبت به کارت‌های فیزیکی
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt=" عروسی"
                src="/asset/media/blog/blog32.jpeg"
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              در دنیای امروز، با پیشرفت تکنولوژی و افزایش محبوبیت ابزارهای
              دیجیتال، کارت‌های عروسی آنلاین به عنوان یک جایگزین مدرن برای
              کارت‌های چاپی، به سرعت در حال گسترش هستند. بسیاری از زوج‌ها ترجیح
              می‌دهند به جای صرف هزینه‌های گزاف برای چاپ کارت، از کارت‌های
              دیجیتال استفاده کنند که هم از نظر هزینه و هم از نظر زمان
              به‌صرفه‌تر است. در این مقاله، به بررسی کامل مزایای کارت عروسی
              دیجیتال نسبت به کارت‌های چاپی می‌پردازیم تا به شما کمک کنیم
              انتخابی هوشمندانه‌تر برای دعوت از مهمانان خود داشته باشید.
            </Typography>

            <Link to="/example-card" style={{ color: "red",marginTop:"15px",display:"block" }}>
              {" "}
              برای دیدن نمونه کارت دیجتال کلیک کنید{" "}
            </Link>

            {/* number 1 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              🌍 ۱. دوستدار محیط زیست
            </Typography>
            <Typography variant="body1">
              یکی از بزرگ‌ترین مزایای کارت عروسی دیجیتال این است که به حفظ محیط
              زیست کمک می‌کند.
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ در فرآیند چاپ کارت، مقدار زیادی کاغذ، جوهر و چسب مصرف می‌شود
                که به تخریب منابع طبیعی منجر می‌شود.
              </li>
              <li>
                ✅ استفاده از کارت‌های دیجیتال باعث می‌شود درختان کمتری قطع شوند
                و از آلودگی ناشی از تولید کاغذ جلوگیری شود.
              </li>
              <li>
                ✅ با حذف حمل‌ونقل و ارسال فیزیکی کارت، میزان انتشار کربن کاهش
                می‌یابد که به سلامت محیط زیست کمک می‌کند.
              </li>
            </ul>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt=" عروسی"
                src="/asset/media/blog/blog31.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 2 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              💰 ۲. کاهش هزینه‌ها
            </Typography>

            <ul className="listBlog">
              <li>
                ✅ چاپ کارت عروسی معمولاً هزینه زیادی دارد؛ از انتخاب نوع کاغذ
                گرفته تا هزینه‌های طراحی و چاپ.
              </li>
              <li>
                ✅ ارسال کارت‌های چاپی (به‌خصوص برای مهمانانی که در شهر یا کشور
                دیگری هستند) باعث افزایش هزینه‌های پست و حمل‌ونقل می‌شود.
              </li>
              <li>
                ✅ کارت‌های دیجیتال نیازی به چاپ و پست ندارند، بنابراین هزینه‌ها
                به شکل قابل توجهی کاهش می‌یابد.
              </li>
            </ul>

            {/* number3 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              🚀 ۳. سرعت و راحتی ارسال
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ ارسال کارت عروسی چاپی زمان‌بر است و ممکن است هفته‌ها طول
                بکشد.
              </li>
              <li>
                ✅ با کارت دیجیتال، می‌توانید تنها با چند کلیک، کارت خود را از
                طریق پیام‌رسان‌ها (مانند واتساپ، تلگرام) یا sms ارسال کنید.
              </li>
              <li>
                ✅ مهمانان به‌سرعت کارت دعوت را دریافت کرده و امکان پاسخ‌گویی
                سریع‌تری دارند.
              </li>
              <li>
                ✅ امکان ارسال کارت به‌صورت هم‌زمان برای تمام مهمان‌ها وجود
                دارد.
              </li>
            </ul>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="عروسی"
                src="/asset/media/blog/blog34.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 4 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              🎨 ۴. انعطاف‌پذیری در طراحی
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ در کارت‌های چاپی، بعد از چاپ دیگر امکان تغییر اطلاعات وجود
                ندارد.
              </li>
              <li>
                ✅ با کارت‌های دیجیتال، اگر تاریخ، مکان یا جزئیات مراسم تغییر
                کند، به‌راحتی می‌توانید اطلاعات کارت را به‌روزرسانی کنید.
              </li>
              <li>
                ✅ امکان انتخاب از بین قالب های آماده با رنگ‌ها، فونت‌ها و
                طرح‌های مختلف وجود دارد.
              </li>
              <li>
                ✅ قابلیت افزودن عکس و موسیقی به کارت دیجیتال باعث می‌شود طراحی
                کارت کاملاً شخصی‌سازی شود.
              </li>
            </ul>
            {/* <Link to="/example-card" style={{ color: "red" }}>
              {" "}
              برای دیدن نمونه کارت ها کلیک کنید{" "}
            </Link> */}

            {/* number 5 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              🎯 ۵. تعامل بیشتر با مهمان‌ها
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ کارت‌های دیجیتال امکان تعامل مستقیم با مهمان‌ها را فراهم
                می‌کنند.
              </li>
              <li>
                ✅ امکان افزودن لینک به لوکیشن محل برگزاری مراسم از طریق Google
                Maps یا Waze برای دسترسی آسان‌تر.
              </li>
              <li>
                ✅ امکان اضافه کردن فرم RSVP (تایید حضور) برای مدیریت بهتر تعداد
                مهمان‌ها.
              </li>
              <li>
                ✅ می‌توانید لینک به گالری عکس‌های عروسی یا لیست هدایا را هم در
                کارت دیجیتال قرار دهید.
              </li>
            </ul>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt=" عروسی"
                src="/asset/media/blog/blog35.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 6 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              📱 ۶. دسترسی آسان و دائمی
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ کارت‌های چاپی ممکن است گم شوند یا خراب شوند، اما کارت دیجیتال
                همیشه در دسترس است.
              </li>
              <li>
                ✅ مهمان‌ها به‌راحتی می‌توانند کارت را در تلفن همراه، لپ‌تاپ یا
                تبلت مشاهده کنند.
              </li>
              <li>✅ در هر زمان و مکان، دسترسی به کارت امکان‌پذیر است.</li>
              <li>
                ✅ نیازی به نگرانی بابت خرابی یا از بین رفتن کارت وجود ندارد.
              </li>
            </ul>

            {/* number 7 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              🌟 ۷. امکان ردیابی و پیگیری آسان
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ با استفاده از کارت دیجیتال، می‌توانید ببینید که چه تعداد از
                مهمان‌ها کارت را باز کرده‌اند.
              </li>
              <li>
                ✅ با قابلیت sms می‌توانید برای یادآوری تاریخ عروسی یا تغییرات
                احتمالی، پیام ارسال کنید.
              </li>
              <li>
                ✅ اگر مهمانی پاسخ ندهد و کارت را نبیند، می‌توانید پیامک یادآوری
                بفرستید.
              </li>
            </ul>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt=" عروسی"
                src="/asset/media/blog/blog36.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* nummber 8 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              💡 ۸. ایجاد تجربه منحصر به فرد
            </Typography>
            <ul className="listBlog">
              <li>
                ✅ کارت‌های دیجیتال به شما این امکان را می‌دهند که با افزودن
                عکس، ویدیو و موسیقی، تجربه‌ای خاص و احساسی برای مهمان‌ها ایجاد
                کنید.
              </li>
              <li>
                ✅ استفاده از انیمیشن‌های ظریف، تم‌های پویا و رنگ‌های زنده باعث
                جذابیت بیشتر کارت می‌شود.
              </li>
              <li>
                ✅ کارت دیجیتال بازتابی از سلیقه و سبک شخصی شماست و به مهمان‌ها
                حس صمیمیت بیشتری می‌دهد.
              </li>
            </ul>

            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              🏆 نتیجه‌گیری
            </Typography>
            <Typography variant="body1">
              کارت عروسی دیجیتال به دلیل کاهش هزینه‌ها، صرفه‌جویی در زمان، حفظ
              محیط زیست، انعطاف‌پذیری بالا و امکان تعامل با مهمان‌ها، به یک
              انتخاب محبوب در میان زوج‌های مدرن تبدیل شده است. اگر به دنبال راهی
              شیک، مقرون‌به‌صرفه و مؤثر برای دعوت از مهمان‌ها هستید، کارت عروسی
              دیجیتال بدون شک بهترین گزینه است. با انتخاب کارت دیجیتال، مراسم
              عروسی خود را به یک رویداد خاص و به‌یادماندنی تبدیل کنید. 🌸💌
            </Typography>

            <Typography variant="body1" style={{marginTop:"20px"}}>
               آیا آماده‌اید کارت عروسی دیجیتال خود را طراحی کنید؟ 
              نمونه‌های موجود در سایت ما رو ببینید و کارت خود را در چند دقیقه آماده
              کنید! 😍✨
              <Link to="/" style={{ color: "red" }}>
                {" "}
                عروسی آنلاین{" "}
              </Link>{" "}
              آماده ارائه خدمات به شماست!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Blog1;
