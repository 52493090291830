import { Box, Grid, Typography } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import jalaliMoment from "jalali-moment";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Navigation from "../../components/Navigation/Navigation.js";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const CommentInstaPage = () => {
  const [comment, setComment] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/instagram-texts`)
      .then((response) => {
        const responseData = response?.data;
        setComment(responseData);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);


  return (
    <>
      <Navigation />
      <main
        style={{
          paddingTop: "30px",
          backgroundColor: "#fff5f0",
          borderRadius: "15px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          maxWidth: "1200px",
          margin: "130px auto 40px",
        }}
      >
        <Grid container>
          <Grid item lg={12} xs={10}>
            <Typography variant="h3" style={{ textAlign: "center" }}>
              نظرات عروس داماد
            </Typography>{" "}
            <Box style={{ margin: "20px 0" }}>
              <TableContainer style={{ marginTop: "10px" }}>
                <Table className="tableMemento">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">#</TableCell>
                      <TableCell align="center">عروس</TableCell>
                      <TableCell align="center">داماد</TableCell>
                      <TableCell align="right">تالار</TableCell>
                      <TableCell align="center">پکیج</TableCell>
                      <TableCell align="center">تاریخ</TableCell>
                      <TableCell align="center">شماره</TableCell>
                      <TableCell align="center">کامنت</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comment?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="right">{index + 1}</TableCell>
                        <TableCell align="center">
                          <div>{item?.bride_name}</div>
                          <div>{item?.bride_instagram}</div>
                        </TableCell>
                        <TableCell align="center">
                          <div>{item?.groom_name}</div>
                          <div>{item?.groom_instagram}</div>
                        </TableCell>
                        <TableCell align="right">
                          <div>{item?.province}</div>
                          <div title={item?.saloon_address}>{item?.saloon_name}</div>
                        </TableCell>
                        <TableCell align="center">
                          {Number(item?.licence_id) === 1 ? (
                            <span style={{ color: "#CD7F32" }}>رایگان</span>
                          ) : (
                            <span style={{ color: "#ADD8E6" }}>لوکس</span>
                          )}
                            {/* <a
                            alt="کارت"
                            href={`https://aroosi.online/${item?.carts[0]?.wedding_id}/${item?.carts[0]?.code}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/asset/media/icon/show-card.png"
                              alt=""
                              style={{ width: "30px" }}
                            />
                          </a> */}
                        </TableCell>
                        <TableCell align="right">
                          <div>
                            {(() => {
                              const persianDate = jalaliMoment(
                                item?.date || "2025-01-26"
                              ).locale("fa");

                              const dayOfWeek = jalaliMoment(item?.date).locale("fa").format("dddd") 
                              const formattedDate =
                                persianDate.format("jYYYY/jMM/jDD"); 

                              const finalDate = `${dayOfWeek}  ${formattedDate}`;
                              return finalDate; 
                            })()}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          {item?.client?.mobile}
                        </TableCell>
                        <TableCell align="center">
                          {item?.text_instagram}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

export default CommentInstaPage;
