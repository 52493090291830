import { Box, Typography, Stack } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import * as data from "../../data";
import { Link } from "react-router-dom";

SwiperCore.use([Autoplay, Navigation, Pagination]);

const BlogSwiper = () => {
  const sliderSettings = {
    freeMode: false,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    // navigation: true,
    className: "mySwiper",
    slidesPerView: 4,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    }, // Move pagination inside its own object
    modules: [Pagination],
    breakpoints: {
      // 1800: { slidesPerView: 2 },
      // 1600: { slidesPerView: 2 },
      // 1400: { slidesPerView: 2 },
      1200: { slidesPerView: 3 },
      900: { slidesPerView: 2 },
      600: { slidesPerView: 2 },
      500: { slidesPerView: 1 },
      1: { slidesPerView: 1 },
    },
  };

  function rgba(r, g, b, a) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  return (
    <Box
      mt={1}
      className="container"
      mb={2}
      px={0}
      sx={{
        padding:"0 15px",
        marginBottom:"50px",
        "& .swiper-slide": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          borderRadius: "8px",
          padding: "15px",
          margin: "15px 0",
          border: "1px solid rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Swiper {...sliderSettings}>
        {data?.blogSwiper?.map((blog, index) => (
          <SwiperSlide key={index}>
            <Stack
              direction="column"
              sx={{
                width: "100%",
                // '&:hover': {
                //   '& .imagebox': {
                //     boxShadow: '0px 0px 5px 0px rgba(255,255,255,0.43)',
                //   },
                // },
              }}
            >
              <Link to={`/blog/blog${blog.id}`} style={{display:"flex",flexDirection:"column"}}>
              <Typography variant="h6" fontWeight={500} mt={1} mb={2} style={{color:"#323232"}}>
                {blog?.title}
              </Typography>
              <img className="imageBg" src={blog.image} alt={blog.title} />
              </Link>
            </Stack>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default BlogSwiper;
