import { Box, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useAuth } from "../../../../../auth/authContext";
import { Switch } from "@material-ui/core";
import { Link } from "react-router-dom";
import DisableLicence from "../../../../../common/DisableLicence";
import { useEffect, useState } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL_AROOSI_ONLINE;

const InstagramPage = () => {
  const { token, fetchCartData, cartData } = useAuth();
  const [textComent, setTextComent] = useState("");

  useEffect(() => {
    setTextComent(cartData?.text_instagram);
  }, [cartData]);

  const initialValues = {
    instagramA: cartData?.bride_instagram || "",
    instagramB: cartData?.groom_instagram || "",
    // comment: cartData?.text_instagram || "",
  };

  const validationSchema = Yup.object({
    instagramA: Yup.string(),
    instagramB: Yup.string(),
    comment: Yup.string(),
  });

  const handleInputChange = (value) => {
    setTextComent(value);
  };

  const handleSubmit = (values) => {
    axios
      .post(
        `${BASE_URL}/api/licence/current`,
        {
          bride_instagram: values?.instagramA,
          groom_instagram: values?.instagramB,
          text_instagram: textComent,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        fetchCartData();
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="formInformation">
          <Grid container spacing={2}>
            <Grid sx={{display:"flex" ,alignItems:"center"}} item xs={12}>
                <img alt="insta" src="asset/media/icon/instagram.png" style={{width:"50px",marginLeft:"10px"}}/>
              <Typography variant="h6">اینستاگرام</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="p">
                برای منشن شدن داخل استوری اختصاصی روز عروسیتون آیدی
                اینستاگرامتون رو وارد کنید:
              </Typography>
            </Grid>

            <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={6}>
              <Field
                type="text"
                id="instagramA"
                name="instagramA"
                placeholder="saba_1999"
              />
              <ErrorMessage
                name="instagramA"
                component="p"
                style={{ color: "red", fontSize: "12px" }}
              />
            </Grid>

            <Grid sx={{ display: "flex", flexDirection: "column" }} item xs={6}>
              <Field
                type="text"
                id="instagramB"
                name="instagramB"
                placeholder="amrmin_ahmadi"
              />
              <ErrorMessage
                name="instagramB"
                component="p"
                style={{ color: "red", fontSize: "12px" }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">
                خیلی خوشحالیم که برای این روز مهم زندگیتون مارو انتخاب کردید.
              </Typography>
              <Typography variant="p">
                لطفا اگر از سایت و تیم پشتیبانی راضی بودید برامون پیام بزارید تا
                عروس دامادهای دیگه هم مارو بهتر بشناسند.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "5px !important" }}>
              <TextField
                value={textComent}
                onChange={(e) => handleInputChange(e?.target?.value)}
                placeholder=""
                multiline
                rows={8}
                variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                InputProps={{
                  style: { fontSize: "14px" },
                }}
              />
            </Grid>

            <Grid
              sx={{ display: "flex", justifyContent: "flex-end" }}
              item
              xs={12}
            >
              <button className="saveInformation" type="submit">
                ذخیره
              </button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default InstagramPage;
