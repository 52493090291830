import React from "react";
import Layout from "../../Layout/Layout";
import { Box, Grid, Typography } from "@mui/material";
import "../Bloges/blogs.css";
import { Link } from "react-router-dom";

const Blog3 = () => {
  return (
    <Layout>
      <Box
        sx={{
          maxWidth: "900px",
          margin: "100px auto",
          boxShadow:
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          borderRadius: "10px",
          padding: "60px 25px",
        }}
      >
        <Grid container spacing={2} sx={{ justifyContent: "center" }}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={{ fontSize: "32px" }}>
              چگونه یک عروسی با بودجه کم ولی شیک برگزار کنیم؟
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt=" عروسی"
                src="/asset/media/blog/wedding8.jpeg"
                style={{
                  width: "100%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              برگزاری عروسی همیشه با هزینه‌های بالا همراه نیست. اگر برنامه‌ریزی
              دقیقی داشته باشید، می‌توانید یک مراسم زیبا و به‌یادماندنی با بودجه
              کم برگزار کنید. در این مقاله نکاتی کاربردی برای صرفه‌جویی در
              هزینه‌ها و در عین حال ایجاد یک فضای شیک و جذاب ارائه می‌دهیم.
            </Typography>

            {/* number 1 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۱. تعیین تاریخ عروسی
            </Typography>
            <Typography variant="body1">
              اولین قدم، تعیین یک بودجه مشخص برای عروسی است. با مشخص کردن سقف
              هزینه، می‌توانید بهتر تصمیم‌گیری کنید و از هزینه‌های اضافی جلوگیری
              کنید. هزینه‌های خود را به دسته‌های مختلف مانند لباس، مکان، پذیرایی
              و دکوراسیون تقسیم کنید.
            </Typography>
            <Typography variant="body1">
              نکته: همیشه مبلغی را برای هزینه‌های غیرمنتظره کنار بگذارید.
            </Typography>

            {/* number 2 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۲. انتخاب مکان مناسب
            </Typography>
            <Typography variant="body1">
              برای صرفه‌جویی در هزینه مکان، گزینه‌های زیر را در نظر بگیرید:
            </Typography>

            <ul className="listBlog">
              <li>
                برگزاری مراسم در خانه یا باغ شخصی: این گزینه علاوه بر صرفه‌جویی
                در هزینه اجاره تالار، فضایی صمیمی‌تر ایجاد می‌کند.
              </li>
              <li>
                اجاره تالارهای کوچک: تالارهای کوچک‌تر معمولاً هزینه کمتری دارند
                و برای مهمانی‌های با تعداد کم مناسب هستند.
              </li>
              <li>
                برگزاری مراسم در روزهای غیرپیک: هزینه تالارها و خدمات در روزهای
                وسط هفته معمولاً کمتر است.
              </li>
            </ul>

            {/* number3 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۳. کاهش تعداد مهمانان
            </Typography>
            <Typography variant="body1">
              یکی از موثرترین راه‌ها برای کاهش هزینه‌ها، کاهش تعداد مهمانان است.
              با دعوت افراد نزدیک و دوستان صمیمی، می‌توانید هزینه پذیرایی، مکان
              و سایر خدمات را به‌طور قابل توجهی کاهش دهید.
            </Typography>
            <Typography variant="body1">
              ایده: یک مهمانی کوچک و صمیمی می‌تواند حتی خاطره‌انگیزتر از یک
              مراسم بزرگ باشد.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="عروسی"
                src="/asset/media/blog/wedding9.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 4 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۴. استفاده از کارت عروسی دیجیتال
            </Typography>
            <Typography variant="body1">
              به جای چاپ کارت‌های کاغذی گران‌قیمت، از کارت‌های عروسی دیجیتال
              استفاده کنید. این کارت‌ها هم هزینه کمتری دارند و هم ارسال آن‌ها
              به‌صورت آنلاین بسیار آسان است.
            </Typography>
            <Typography variant="body1">
              <Link to="/" style={{ color: "red" }}>
                {" "}
                عروسی آنلاین{" "}
              </Link>
              تخصصی ترین سایت کارت دیجتال عروسی هست که کارت هامون قابلیت های
              زیادی داره مثل:
            </Typography>
            <ul className="listBlog">
              <li>امکان ارسال sms دعوت به شماره مهمان‌ها تنها با یک کلیک</li>
              <li>
                ارسال sms یادآوری روز مراسم، تشکر برای حضور در مراسم، رزرو
                پارکینگ برای مهمان‌های نزدیک و ...
              </li>
              <li>
                امکان استفاده از برنامه های مسیریابی مثل ویز، گوگل مپ، نشان و
                بلد
              </li>
              <li>
                ساخت انواع نظرسنجی مثل نظرسنجی حضور در مراسم برای مدیریت تعداد
                مهمان‌ها و هزینه
              </li>
              <li>امکان ثبت یادبود و پیام تبریک توسط مهمان‌ها</li>
              <li>پخش موزیک در پس زمینه کارت</li>
              <li>
                نمایش عکس دلخواه در پس زمینه کارت یا عکس عروس داماد به صورت تکی
              </li>
              <li>امکان انتخاب قالب های متنوع</li>
              <li>
                امار و ارقام دقیق از مراسم مثل تعداد مهمان و کارت ها، تعداد
                مهمان هایی که در مراسم شرکت میکنند و...
              </li>
            </ul>
            <Link to="/example-card" style={{ color: "red" }}>
              {" "}
              برای دیدن نمونه کارت ها کلیک کنید{" "}
            </Link>

            {/* number 5 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۵. اجاره یا خرید لباس با قیمت مناسب
            </Typography>
            <Typography variant="body1">
              لباس عروس یکی از بخش‌های پرهزینه مراسم است. برای کاهش هزینه‌ها:
            </Typography>
            <ul className="listBlog">
              <li>لباس عروس را اجاره کنید</li>
              <li>به دنبال فروشگاه‌هایی باشید که تخفیف ویژه ارائه می‌دهند</li>
              <li>
                از لباس‌های ساده‌تر با طراحی مینیمال استفاده کنید که معمولاً
                قیمت کمتری دارند و همیشه مد هستند.
              </li>
            </ul>
            <Typography variant="body1">
              نکته: لباس داماد را نیز می‌توانید از فروشگاه‌های اجاره لباس تهیه
              کنید.
            </Typography>

            {/* number 6 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۶. دکوراسیون ساده و زیبا
            </Typography>
            <Typography variant="body1">
              برای ایجاد فضایی شیک با بودجه کم، به نکات زیر توجه کنید:
            </Typography>
            <ul className="listBlog">
              <li>از گل‌های طبیعی فصل استفاده کنید که قیمت کمتری دارند</li>
              <li>از لوازم تزئینی ساده و دست‌ساز بهره ببرید</li>
              <li>
                نورپردازی خلاقانه با استفاده از شمع‌ها و چراغ‌های ریسه‌ای
                می‌تواند جلوه‌ای بسیار زیبا و خاص ایجاد کند
              </li>
            </ul>
            <Typography variant="body1">
              ایده: میزهای پذیرایی را با استفاده از گلدان‌های ساده و گل‌های تازه
              تزئین کنید.
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="عروسی"
                src="/asset/media/blog/wedding7.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 7 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۷. پذیرایی اقتصادی
            </Typography>
            <Typography variant="body1">
              پذیرایی بخش بزرگی از هزینه‌های عروسی را شامل می‌شود. برای کاهش
              هزینه:
            </Typography>
            <ul className="listBlog">
              <li>تعداد غذاها را به یک یا دو گزینه محدود کنید.</li>
              <li>از فینگرفود های قیمت مناسب در طول مراسم استفاده کنید</li>
              <li>بجز طبقه اخر کیک از ماکت کیک استفاده کنید</li>
            </ul>
            <Typography variant="body1">
              نکته: کیفیت غذا از کمیت آن مهم‌تر است، پس تمرکز خود را بر ارائه
              غذاهای باکیفیت بگذارید.
            </Typography>

            {/* nummber 8 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۸. استفاده از خدمات دوستان و آشنایان
            </Typography>
            <Typography variant="body1">
              اگر دوستان یا آشنایانی دارید که در زمینه‌هایی مانند عکاسی، موزیک،
              یا آشپزی تخصص دارند، می‌توانید از آن‌ها کمک بگیرید. این کار
              می‌تواند هزینه‌ها را به‌طور قابل توجهی کاهش دهد.
            </Typography>

            {/* number 9  */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۹. برنامه‌ریزی دقیق و حذف هزینه‌های غیرضروری
            </Typography>
            <Typography variant="body1">
              با برنامه‌ریزی دقیق می‌توانید از هزینه‌های اضافی جلوگیری کنید.
              برای مثال:
            </Typography>
            <ul className="listBlog">
              <li>
                از خدمات لوکس غیرضروری مانند اجاره خودروهای گران‌قیمت صرف‌نظر
                کنید.
              </li>
              <li>
                روی مواردی تمرکز کنید که برای شما و مهمانانتان اولویت دارند.
              </li>
            </ul>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="عروسی"
                src="/asset/media/blog/wedding10.jpeg"
                style={{
                  width: "80%",
                  borderRadius: "15px",
                  margin: "20px auto",
                }}
              />
            </Box>

            {/* number 10 */}
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              ۱۰. ماه عسل اقتصادی
            </Typography>
            <Typography variant="body1">
              اگر بودجه شما محدود است، مقصدی نزدیک‌تر و ارزان‌تر را برای ماه عسل
              انتخاب کنید. حتی می‌توانید یک سفر کوتاه داخل شهری را برنامه‌ریزی
              کنید.
            </Typography>
            <Typography variant="body1">
              ایده: کمپینگ در طبیعت یا اقامت در یک هتل محلی می‌تواند تجربه‌ای
              متفاوت و خاص باشد.
            </Typography>

            <Typography
              variant="h3"
              sx={{ fontSize: "24px", margin: "20px 0 10px" }}
            >
              جمع‌بندی
            </Typography>
            <Typography variant="body1">
              با برنامه‌ریزی دقیق و اولویت‌بندی نیازها، می‌توانید یک مراسم عروسی
              شیک و زیبا با بودجه کم برگزار کنید. نکات این مقاله را به کار
              بگیرید و به یاد داشته باشید که زیبایی یک مراسم به سادگی و احساسات
              پشت آن بستگی دارد، نه به هزینه‌های بالا. اگر به دنبال کارت عروسی
              دیجیتال با طراحی اختصاصی هستید،{" "}
              <Link to="/" style={{ color: "red" }}>
                {" "}
                عروسی آنلاین{" "}
              </Link>{" "}
              آماده ارائه خدمات به شماست!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Blog3;
