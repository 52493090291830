const MakeWithLove = () => {
  return (
    <div
      style={{
        width: "100%",
        padding: "6px 10px",
        marginBottom: "20px",
      }}
    >
      {/* -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */}
      <a
        target="blank"
        href="/"
        style={{
          display: "block",
          textAlign: "center",
          color: "#323232",
        //   WebkitTextStrokeWidth: "0.5px",
        //   WebkitTextStrokeColor: "#fff",
        // textShadow: "1px 1px 2px #fff",
        textShadow: "#fff 1px 0 10px",
        }}
      >
        ساخته شده با عشق توسط عروسی آنلاین ❤️
      </a>
    </div>
  );
};

export default MakeWithLove;
