import { useState } from "react";
import "../Dashboard/DashboardPage.css";
import CardPageExample from "./Card/CardPageExample";
import CardPageExample2 from "./Card2/CardPageExample2";
import CardPageExample3 from "./Card3/CardPageExample3";
import CardPageExample4 from "./Card4/CardPageExample4";
import CardPageExample5 from "./Card5/CardPageExample5";
import CardPageExample6 from "./Card6/CardPageExample6";
import CardPageExample7 from "./Card7/CardPageExample7";
import CardPageExample8 from "./Card8/CardPageExample8";
import { Helmet } from "react-helmet-async";
import CardPageExample9 from "./Card9/CardPageExample9";
import CardPageExample10 from "./Card10/CardPageExample10";

const ExampleCardPage = () => {
  const [activeSection, setActiveSection] = useState("section4");

  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <>
      <Helmet>
        <title>عروسی آنلاین - کارت های نمونه</title>
        <meta
          name="description"
          content="نمونه کارت های عروسی آنلاین"
        />
      </Helmet>
      <div className="">
        <div
          style={{
            position: "sticky",
            top: "0",
            left: "0",
            zIndex: "1",
            display: "flex",
            justifyContent: "space-evenly",
            background: "#f5f6fa",
            padding: "10px 0",
          }}
        >
          {Array.from({ length: 10 }, (_, i) => {
            const sectionId = `section${i + 1}`;
            return (
              <span
                key={i}
                onClick={() => handleSectionClick(sectionId)}
                style={{
                  border:
                    activeSection === sectionId ? "2px solid red" : "none",
                  padding: "2px",
                  borderRadius: "10px",
                }}
              >
                <img
                  alt=""
                  src={`/asset/media/pic/carts/cart${i + 1}.jpg`}
                  style={{
                    width: "100%",
                    maxWidth: "50px",
                    height: "50px",
                    borderRadius: "8px",
                  }}
                />
              </span>
            );
          })}
        </div>

        <div className="cardpageExample">
          {activeSection === "section1" && <CardPageExample />}
          {activeSection === "section2" && <CardPageExample2 />}
          {activeSection === "section3" && <CardPageExample3 />}
          {activeSection === "section4" && <CardPageExample4 />}
          {activeSection === "section5" && <CardPageExample5 />}
          {activeSection === "section6" && <CardPageExample6 />}
          {activeSection === "section7" && <CardPageExample7 />}
          {activeSection === "section8" && <CardPageExample8 />}
          {activeSection === "section9" && <CardPageExample9 />}
          {activeSection === "section10" && <CardPageExample10 />}
        </div>
      </div>
    </>
  );
};

export default ExampleCardPage;
